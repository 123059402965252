import { render, staticRenderFns } from "./LocaleCard.vue?vue&type=template&id=364b5324&scoped=true"
import script from "./LocaleCard.vue?vue&type=script&lang=js"
export * from "./LocaleCard.vue?vue&type=script&lang=js"
import style0 from "./LocaleCard.vue?vue&type=style&index=0&id=364b5324&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364b5324",
  null
  
)

export default component.exports